import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import chartPic from "@images/home/mecomChart.png";
import { trendData } from "@home3/homeType";
import { formatRate } from "@/utils/format";
import { convertToI18, formatDigit } from "@/utils/count";
type Props = {
    trendData: trendData;
};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");
    const { trendData } = props;
    return (
        <div className="bg-[#fff] px-[20px] pb-[30px]">
            <div className={styles.title}>{t("ecomTrend")}</div>
            <div className={styles.time}>{t("update12hour")}</div>
            <div className={styles.main}>
                <div className={styles.up}>
                    <div className={styles.sale}>{t("GMV")}</div>
                    <div className="flex items-baseline">
                        <span className={styles.saleCount}>
                            ${formatDigit(trendData?.gmv)}
                        </span>
                        <span className={styles.saleRate}>
                            {formatRate(trendData?.gmvIncrRate, true)}
                        </span>
                    </div>
                    <div className={styles.info}>
                        <div>
                            <div className={styles.countName}>
                                {t("produts1")}
                            </div>

                            <div className={styles.count}>
                                {convertToI18(trendData?.productCount)}
                            </div>
                            <div className={styles.countAdd}>
                                {formatRate(
                                    trendData?.productCountIncrRate,
                                    true
                                )}
                            </div>
                        </div>
                        <div>
                            <div className={styles.countName}>
                                {t("salesVolume")}
                            </div>

                            <div className={styles.count}>
                                ${convertToI18(trendData?.totalSales)}
                            </div>
                            <div className={styles.countAdd}>
                                {formatRate(
                                    trendData?.totalSalesIncrRate,
                                    true
                                )}
                            </div>
                        </div>
                        {/* <div>
                            <div className={styles.countName}>
                                {t("participants")}
                            </div>
                            <div className={styles.count}>
                                {convertToI18(99)}
                            </div>
                            <div className={styles.countAdd}>
                                {formatRate(1.1, true)}
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={styles.down}>
                    <div className={styles.pic}>
                        <Image alt="" src={chartPic}></Image>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
